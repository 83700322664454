import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function fetchWordList(params) {
  return axios.get(baseUrl + `/medical/word`, { params, headers });
}
export function fetchMedicalList(params) {
  return axios.get(baseUrl + `/medical/list`, { params, headers });
}
export function fetchMedicalDetail(id) {
  return axios.get(baseUrl + `/medical/${id}`, { headers });
}
export function removeWord(data) {
  return axios.put(baseUrl + `/medical/word`, data, { headers });
}
