<template>
  <div class="dimbox search">
    <button class="btn" @click="close">
      <img src="@/assets/images/close-black.svg" alt="close" />
    </button>
    <div class="box flex">
      <div class="flex">
        <div class="inputBox flex">
          <span class="material-icons-outlined"> category </span>
          <input
            class="gene"
            type="text"
            placeholder="Type your gene (eg. BRCA1, APOE)"
            v-model="modalSearchWord"
          />
        </div>
        <div class="inputBox flex">
          <span class="material-icons-outlined"> share_location </span>
          <input
            class="place"
            type="text"
            placeholder="City, State or Zip code"
            v-model="modalAddressWord"
          />
        </div>
      </div>
      <button @click="submit()">
        <span class="material-icons-outlined"> search </span>
      </button>
    </div>
  </div>
</template>
<script>
// import { CALL_CENTER } from "@/config/env";
import gtag from "ga-gtag";

export default {
  props: {
    searchWord: String,
  },
  data() {
    return {
      // CALL_CENTER: CALL_CENTER,
      modalAddressWord: "",
      modalSearchWord: this.searchWord ? this.searchWord : "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      const eventName = `Modal search: ${this.modalSearchWord}/${this.modalAddressWord}`;
      gtag("event", eventName, {
        event_category: "Modal-search_category",
        event_action: "Modal-search_action",
        event_label: `${this.modalSearchWord}/${this.modalAddressWord}`,
      });
      this.close();
      this.$emit("submit", this.modalSearchWord, this.modalAddressWord);
    },
  },
};
</script>
