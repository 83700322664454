<template>
  <div class="container list" v-loading="loading">
    <div>
      <div class="left">
        <div class="searchBox flex">
          <div class="flex">
            <div class="inputBox flex">
              <input
                class="gene"
                type="text"
                placeholder="Gene"
                v-model="searchWord"
                @keyup.enter="handleSearch"
              />
            </div>
            <div class="inputBox flex">
              <input
                class="place"
                type="text"
                placeholder="City, State or Zip code"
                v-model="addressWord"
                @keyup.enter="handleSearch"
              />
            </div>
            <div class="flex">
              <div class="mobile">
                <input
                  class="gene"
                  type="text"
                  placeholder="Type your gene (eg. BRCA1, APOE)"
                  v-model="searchWord"
                  @keyup.enter="handleSearch"
                />
              </div>
              <div class="mobile place">
                <input
                  class="place"
                  type="text"
                  placeholder="City, State or Zip code"
                  v-model="addressWord"
                  @keyup.enter="handleSearch"
                />
              </div>
              <button class="mobileBtn" @click="handleSearch">
                <span class="material-icons-outlined"> search </span>
              </button>
            </div>
            <!-- <button class="mobileBtn" @click="handleSearchModal">
              <span class="material-icons-outlined"> search </span>
            </button> -->
          </div>
          <button class="pc" @click="handleSearch">
            <span class="material-icons-outlined"> search </span>
          </button>
        </div>
        <div class="history" v-if="isLogin">
          <!-- @close="handleClose(tag)" -->
          <!-- closable -->
          <!-- :type="tag.type" -->
          <el-tag
            v-for="(item, i) in latestWords"
            :key="i"
            @click="handleWord(item.type, item.word)"
          >
            {{ item.word }}
          </el-tag>
        </div>
        <!-- <div class="sort">
          <ul>
            <li>
              <button class="active">Gene</button>
              <button @click="handleSort('gene')" class="active">Gene</button>
            </li>
            <li>
              <button>Protein</button>
            </li>
            <li><button>Protein</button></li>
            <li><button>Protein</button></li>
          </ul>
        </div> -->
        <ul class="list" ref="list" :class="{ high: isLogin == false }">
          <li
            v-for="(data, i) in searchList"
            :key="i"
            @click="setMarker(data.address)"
          >
            <div class="img">
              <img
                :src="imageHandler(data)"
                :class="!isImage(data) && `basic-profile`"
                alt="Profile Image"
              />
            </div>
            <div class="flexB">
              <div class="info">
                <h3>
                  {{ data.name }}
                  <span>{{ data.title !== "NaN" ? data.title : null }}</span>
                </h3>
                <router-link
                  :to="{ name: 'detail', params: { medicalId: data._id } }"
                  class="more"
                >
                  <h3 @click="gtagHandler(data.name)">
                    {{ data.name }}
                    <span>{{ data.title !== "NaN" ? data.title : null }}</span>
                  </h3>
                </router-link>
                <p>
                  <span v-for="(item, i) in data.specialty" :key="i">
                    {{ item }}
                  </span>
                </p>
                <p>{{ data.address }}</p>
                <p>{{ data.contact }}</p>
                <div class="txt">
                  <pre>{{ data.description }}</pre>
                </div>
              </div>
              <router-link
                :to="{ name: 'detail', params: { medicalId: data._id } }"
                class="point"
                >+ More</router-link
              >
            </div>
          </li>
        </ul>
        <NoData
          v-if="searchList.length < 1"
          :class="{ high: isLogin == true }"
        />
        <div class="pagination" v-if="searchList.length > 0">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            :currentPage="page"
            :pager-count="5"
            :page-size="10"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </div>
      <div class="right">
        <!-- google map -->
        <div class="map">
          <div id="map"></div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <SearchModal
          v-if="contactVisible"
          @close="close"
          @submit="childPopup"
          :searchWord="searchWord"
        />
        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBHz9WFkmS_J-IDeGpOfMy9OQTGzbs8I94&language=en"></script>
<script>
import { mapState } from "vuex";
// import moment from "moment";
// import { format } from "@/mixins/format";
import SearchModal from "@/components/pop/SearchModal";
import { fetchMedicalList, fetchWordList } from "@/api/medical";
import NoData from "@/components/contents/Nodata";
import gtag from "ga-gtag";

export default {
  // mixins: [format],
  components: { SearchModal, NoData },
  data() {
    return {
      // moment: moment,
      modalVisible: false,
      contactVisible: false,
      loading: false,
      searchList: [],
      total: 0,
      page: 1,
      map: null,
      geocoder: null,
      mapCenter: { lat: 40.78448, lng: -74.005 },
      labels: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      labelIndex: 0,
      // bands: {
      //   lat: 35.106187,
      //   lng: 129.042943,
      // },
      // bands2: {
      //   lat: 35.105556,
      //   lng: 129.04393,
      // },
      searchWord: "",
      addressWord: "",
      // tags: [
      //   { name: "Rmx", type: "" },
      //   { name: "Tag 2", type: "success" },
      //   { name: "Tag 3", type: "info" },
      //   { name: "Tag 4", type: "warning" },
      //   { name: "Tag 5", type: "danger" },
      // ],
      latestWords: [],
      pageFlag: true,
    };
  },
  created() {
    if (this.$route.query.searchWord || this.$route.query.addressWord) {
      this.searchWord = this.$route.query.searchWord;
      this.addressWord = this.$route.query.addressWord;
      this.page = this.$route.query.page;
      this.pageFlag = this.$route.params.pageFlag;
      this.getSearchList();
    }
  },
  computed: {
    ...mapState(["userId", "isLogin"]),
  },
  mounted() {
    this.initMap();
    // this.setMarker(this.mapCenter, "ce");
    // this.setMarker(this.bands, "1");
    // this.setMarker(this.bands2, "2");
    this.getUserLastestWord();
  },
  methods: {
    isImage(data) {
      return !!data.img_url;
    },
    imageHandler(data) {
      return data.img_url
        ? data.img_url
        : data.gender === "Male"
        ? require(`@/assets/images/male_avatar.png`)
        : require(`@/assets/images/female_avatar.png`);
    },
    gtagHandler(name) {
      const eventName = `List click: ${name}`;
      gtag("event", eventName, {
        event_category: "List-click_category",
        event_action: "List-click_action",
        event_label: `${name}`,
      });
    },
    getUserLastestWord() {
      // console.log(this.userId);
      if (this.isLogin == true) {
        let params = {
          limit: 10,
        };
        fetchWordList(params).then((res) => {
          if (res.data.status == 200) {
            this.latestWords = res.data.data;
          } else {
            return alert(res.data.message);
          }
        });
      }
    },
    initMap() {
      //getElementById로 map id 속성의 요소를 가져옴
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.mapCenter, //center로 할 위도, 경도를 지정
        zoom: 17, //zoom size를 지정
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: true,
        gestureHandling: "cooperative",
        mapTypeControl: true,
        zoomControl: true,
        scaleControl: true,
        rotateControl: true,
        fullscreenControl: true,
        mapTypeControl: true,
      });
      this.geocoder = new google.maps.Geocoder();
    },
    //지도에 마커를 찍는 함수.
    setMarker(address) {
      this.codeAddress(this.geocoder, this.map, address);
    },
    // handleClose(tag) {
    //   this.tags.splice(this.tags.indexOf(tag), 1);
    // },
    codeAddress(geocoder, map, address) {
      geocoder.geocode({ address: address }, function (results, status) {
        if (status === "OK") {
          map.setCenter(results[0].geometry.location);
          var marker = new google.maps.Marker({
            map: map,
            position: results[0].geometry.location,
          });
        } else {
          /*220801 - remove map temporarily*/
          // alert(
          //   "Geocode was not successful for the following reason: " + status
          // );
          console.error(
            "Geocode was not successful for the following reason: ",
            status
          );
        }
      });
    },
    close() {
      this.modalVisible = false;
      this.contactVisible = false;
    },
    handleSearchModal() {
      this.modalVisible = true;
      this.contactVisible = true;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.pageFlag = true;
      //this.getSearchList();
      this.searchControl();
    },
    handleWord(type, word) {
      this.page = 1;
      this.pageFlag = true;
      if (type == "address") {
        this.addressWord = word;
      } else {
        this.searchWord = word;
      }
      this.searchControl();
    },
    handleSearch() {
      this.page = 1;
      this.pageFlag = false;
      this.searchControl();
    },
    childPopup(modalSearchWord, modalAddressWord) {
      this.pageFlag = false;
      this.page = 1;
      this.searchWord = modalSearchWord;
      this.addressWord = modalAddressWord;
      this.searchControl();
    },
    searchControl() {
      let query = {};
      let params = {};
      if (this.searchWord == "" && this.addressWord == "") {
        return alert("Enter keyword.");
      }
      if (this.searchWord != "" && this.searchWord != undefined) {
        query.searchWord = this.searchWord;
      }
      if (this.addressWord != "" && this.addressWord != undefined) {
        query.addressWord = this.addressWord;
      }
      if (this.page != "" && this.page != undefined) {
        query.page = this.page;
      }
      params.pageFlag = this.pageFlag;
      this.$router.push({
        name: "list",
        query: query,
        params: params,
      });
    },
    getSearchList() {
      // if (this.searchWord == "" && this.addressWord == "") {
      //   return alert("검색어를 입력해주세요.");
      // }
      this.loading = true;
      if (this.$refs.list) {
        this.$refs.list.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
      let params = {
        pageFlag: this.pageFlag == undefined ? true : this.pageFlag,
        userId: this.userId,
        page: this.page,
        searchWord: this.searchWord,
        addressWord: this.addressWord,
      };
      // console.log("getSearchList", params);
      fetchMedicalList(params).then((res) => {
        this.pageFlag = true;
        if (res.data.status === 200) {
          const listData = res.data.data;

          // Render pictures first
          const havePics = listData.filter((item) => item.img_url);
          const noPics = listData.filter((item) => !item.img_url);
          const searchList = havePics.concat(noPics);
          this.searchList = searchList;

          this.total = res.data.total;
          this.page = Number(res.data.page);
          this.getUserLastestWord();
          if (this.searchList.length > 0) {
            this.codeAddress(
              this.geocoder,
              this.map,
              this.searchList[0].address
            );
          }
        } else {
          return alert(res.data.message);
        }
        this.loading = false;
      });
    },
  },
};
</script>
